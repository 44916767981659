
function mobileMenuToggle({ document }) {

    const body = document.querySelector('body');
    const header = document.querySelector('#header');
    const toggle = document.querySelector('#navigation-toggle');

    const handleToggleClick = () => {

        if (!header.classList.contains('animated')) {
            header.classList.add('animated');
        }

        if (header.classList.contains('open')) {
            header.classList.remove('open');
            body.style.overflow = 'auto';
        } else {
            header.classList.add('open');
            body.style.overflow = 'hidden';
        }
    }

    if (toggle) {
        toggle.addEventListener('click', handleToggleClick);
    }
}

export default mobileMenuToggle;
