import ResizeObserver from 'resize-observer-polyfill';
import { breakpoints } from "../../common/breakpoints";

const elementQuery = ({ document }) => {
    const observer = new ResizeObserver(function (entries) {
        const defaultBreakpoints = breakpoints;

        entries.forEach(function (entry) {
            const breakpoints = entry.target.dataset.breakpoints ?
                JSON.parse(entry.target.dataset.breakpoints) :
                defaultBreakpoints;

            Object.keys(breakpoints).forEach(function (breakpoint) {
                const minWidth = breakpoints[breakpoint];
                const breakPointClass = `media-element-${breakpoint}`;
                if (entry.contentRect.width >= minWidth) {
                    entry.target.classList.add(breakPointClass);
                } else {
                    entry.target.classList.remove(breakPointClass);
                }
            });
        });
    });

    const elements = document.querySelectorAll('[data-observe-resize]');
    for (let i = 0; i < elements.length; i++) {
        observer.observe(elements[i]);
    }
};

export default elementQuery;
