import IntlPhoneInput from './intlPhoneInput';
import 'intl-tel-input/build/css/intlTelInput.css';
import { getElement } from '../../../common/asyncElementGetter';

async function intlPhoneInput({ http }) {
  const inputs = await getElement('.intl-phone-input', true, false, 300, 5);
  inputs && inputs.forEach(input => {
    IntlPhoneInput({ input, http });
  });
}

export default intlPhoneInput;
