import React from 'react';
import { SvgIcon } from '@mui/material';

const Online = (props) => (
  <SvgIcon width="72" height="68" viewBox="0 0 72 68" fill="none" {...props}>
    <path d="M36.0006 64.6665H52.334M36.0006 64.6665H19.6673M36.0006 64.6665V48.3332M36.0006 48.3332H7.41732C5.16217 48.3332 3.33398 46.5051 3.33398 44.2498V7.49984C3.33398 5.24465 5.16217 3.4165 7.41732 3.4165H64.584C66.8392 3.4165 68.6673 5.24469 68.6673 7.49984V44.2498C68.6673 46.5051 66.8392 48.3332 64.584 48.3332H36.0006Z" stroke="#BEBEBE" fill="none" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Online;
