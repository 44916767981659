import React from 'react';
import { SvgIcon } from '@mui/material';

const Bill = (props) => (
  <SvgIcon width="64" height="80" viewBox="0 0 64 80" fill="none" {...props}>
    <path d="M15.666 44.0833H40.166M15.666 60.4167H48.3327M36.0827 3.25H3.41602V76.75H60.5827V27.75M36.0827 3.25H40.166L60.5827 23.6667V27.75M36.0827 3.25V19.5833C36.0827 23.6667 40.166 27.75 44.2493 27.75H60.5827" fill="none" stroke="#BEBEBE" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Bill;
