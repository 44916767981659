import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

const IntlPhoneInput = ({ input, http, options }) => {
  const phoneValidatorError = document.querySelector('.invalid-phone-number');
  const submitEl = document.querySelector("[type='submit']");

  const geoIpLookup = async(success, failure) => {
    await http.get('https://ipinfo.io/json')
      .then(res => {
        const { country } = res.data;
        success(country);
      })
      .catch(() => success('br'));
  };

  const iti = intlTelInput(input, options || {
    initialCountry: 'auto',
    separateDialCode: false,
    formatOnDisplay: true,
    geoIpLookup
  });

  const getDialCode = () => {
    const countryData = iti.getSelectedCountryData();
    return countryData ? countryData.dialCode : '55';
  };

  const replaceLetters = (value) => String(value).replace(/[^0-9]/g, '');

  const removeDialCode = (value) => String(value).replace(`+${getDialCode()}`, '');

  const addDialCode = (value) => `+${getDialCode()}${value}`;

  const handleInputValue = (value) => {
    const countryCodeStr = `+${getDialCode()}`;

    if (value === countryCodeStr.substring(0, countryCodeStr.length - 1)) {
      input.value = '';
      return false;
    }

    const buffer = replaceLetters(removeDialCode(value));
    input.value = addDialCode(buffer);
  };

  if (iti.getSelectedCountryData().dialCode) {
    handleInputValue(input.value);
  }

  input.addEventListener('input', e => handleInputValue(e.target.value));

  input.addEventListener('blur', () => {
    if (input.value.trim()) {
      if (iti.isValidNumber()) {
        phoneValidatorError.classList.add('d-none');
      } else {
        phoneValidatorError.classList.remove('d-none');
      }
    }
  });

  if (submitEl) {
    submitEl.addEventListener('click', () => {
      phoneValidatorError.classList.add('d-none');
    });
  }
};

export default IntlPhoneInput;
