
function languageSwitcher({ document }) {

    const switcher = document.querySelector('#language-switcher');

    const handleChange = () => {
        window.location.href = switcher.value;
    }

    if (switcher) {
        switcher.addEventListener('change', handleChange);
    }
}

export default languageSwitcher;
