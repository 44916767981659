import React from 'react';
import { SvgIcon } from '@mui/material';

const Payment = (props) => (
  <SvgIcon width="80" height="56" viewBox="0 0 80 56" fill="none" {...props}>
    <path d="M76.75 19.8333V11.6667C76.75 7.15634 73.0938 3.5 68.5833 3.5H11.4167C6.90634 3.5 3.25 7.15634 3.25 11.6667V19.8333M76.75 19.8333V44.3333C76.75 48.8438 73.0938 52.5 68.5833 52.5H11.4167C6.90634 52.5 3.25 48.8438 3.25 44.3333V19.8333M76.75 19.8333H3.25M15.5 40.25H31.8333" stroke="#BEBEBE" fill="none" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Payment;
