import React from 'react';
import { SvgIcon } from '@mui/material';

const Support = (props) => (
  <SvgIcon width="84" height="62" viewBox="0 0 84 62" fill="none" {...props}>
    <path d="M70.584 48.3752H13.4173M70.584 48.3752C72.8392 48.3752 74.6673 46.5471 74.6673 44.2918V7.54183C74.6673 5.28669 72.8392 3.4585 70.584 3.4585H13.4173C11.1622 3.4585 9.33398 5.28665 9.33398 7.54183V44.2918C9.33398 46.5471 11.1622 48.3752 13.4173 48.3752M70.584 48.3752H75.6881C78.5073 48.3752 80.7923 50.6602 80.7923 53.4793C80.7923 56.2985 78.5073 58.5835 75.6881 58.5835H8.31315C5.49418 58.5835 3.20898 56.2985 3.20898 53.4793C3.20898 50.6602 5.49418 48.3752 8.31315 48.3752H13.4173" stroke="#BEBEBE" fill="none" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Support;
