import React from 'react';
import { SvgIcon } from '@mui/material';

const Security = (props) => (
  <SvgIcon width="80" height="60" viewBox="0 0 80 60" fill="none" {...props}>
    <path d="M56.3333 17.7502L76.75 9.5835V50.4168L56.3333 42.2502M7.33333 3.4585H52.25C54.5052 3.4585 56.3333 5.28669 56.3333 7.54183V52.4585C56.3333 54.7137 54.5052 56.5418 52.25 56.5418H7.33333C5.07819 56.5418 3.25 54.7137 3.25 52.4585V7.54183C3.25 5.28669 5.07819 3.4585 7.33333 3.4585Z" stroke="#BEBEBE" fill="none" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Security;
