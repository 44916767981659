import React from 'react';
import { SvgIcon } from '@mui/material';

const Credibility = (props) => (
  <SvgIcon width="96" height="64" viewBox="0 0 96 64" fill="none" {...props}>
    <path d="M11.2507 60.5832H3.08398V56.4998C3.08398 48.8893 8.28929 42.494 15.334 40.681M23.5006 27.2195C18.7428 25.538 15.334 21.0003 15.334 15.6665C15.334 10.3328 18.7428 5.79525 23.5006 4.11361M84.7506 60.5832H92.9173V56.4998C92.9173 48.8893 87.7119 42.494 80.6673 40.681M72.5006 4.11361C77.2585 5.79525 80.6673 10.3328 80.6673 15.6665C80.6673 21.0003 77.2585 25.538 72.5006 27.2195M39.834 40.1665H56.1673C65.1878 40.1665 72.5006 47.4793 72.5006 56.4998V60.5832H23.5006V56.4998C23.5006 47.4793 30.8133 40.1665 39.834 40.1665ZM60.2506 15.6665C60.2506 22.432 54.7663 27.9165 48.0006 27.9165C41.235 27.9165 35.7506 22.432 35.7506 15.6665C35.7506 8.90103 41.235 3.4165 48.0006 3.4165C54.7663 3.4165 60.2506 8.90103 60.2506 15.6665Z" stroke="#BEBEBE" fill="none" strokeWidth="6" strokeLinecap="round" strokeLinejoin="round" />
  </SvgIcon>
);

export default Credibility;
